// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-before-js": () => import("./../../../src/templates/before.js" /* webpackChunkName: "component---src-templates-before-js" */),
  "component---src-templates-before-single-js": () => import("./../../../src/templates/beforeSingle.js" /* webpackChunkName: "component---src-templates-before-single-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/defaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-doctor-referral-js": () => import("./../../../src/templates/doctor-referral.js" /* webpackChunkName: "component---src-templates-doctor-referral-js" */),
  "component---src-templates-financial-information-js": () => import("./../../../src/templates/financial-information.js" /* webpackChunkName: "component---src-templates-financial-information-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-office-visits-js": () => import("./../../../src/templates/office-visits.js" /* webpackChunkName: "component---src-templates-office-visits-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-referral-form-js": () => import("./../../../src/templates/referral-form.js" /* webpackChunkName: "component---src-templates-referral-form-js" */),
  "component---src-templates-reviews-js": () => import("./../../../src/templates/reviews.js" /* webpackChunkName: "component---src-templates-reviews-js" */),
  "component---src-templates-treatment-single-js": () => import("./../../../src/templates/treatment-single.js" /* webpackChunkName: "component---src-templates-treatment-single-js" */),
  "component---src-templates-treatments-js": () => import("./../../../src/templates/treatments.js" /* webpackChunkName: "component---src-templates-treatments-js" */),
  "component---src-templates-why-choose-js": () => import("./../../../src/templates/why-choose.js" /* webpackChunkName: "component---src-templates-why-choose-js" */)
}

